$(function() {

    $('.slick-product').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true
    });

    $('#slice-portas-index').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1
    });

    $('#slick-hero-index').slick({

    });


    $('#slick-single-product').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '#slider-product-nav'
    });
    //
    $('#slider-product-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        margin: 15,
        asNavFor: '#slick-single-product',
        dots: true,
        centerMode: true,
        focusOnSelect: true
    });

});
